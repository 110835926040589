import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Flex, { FlexItem } from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import MainLayout from '../components/MainLayout';
import ContentAtHome from '../components/ContentAtHome';

import HomeTopArea from '../components/HomeTopArea';
import AnnouncementsArea from '../components/AnnouncementsArea';
import MedicalGuidanceArea from '../components/MedicalGuidanceArea';
import AccessInfoArea from '../components/AccessInfoArea';

import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import Link from '../components/Link';
import { H2, H2Background, H3, H4 } from '../components/common/H.style';
import { UL } from '../components/common/UL.style';
import { LI } from '../components/common/LI.style';

const Wrapper = styled.div`
  margin: 50px 0;
`;

const CovidButton = styled(Flex)`
  height: 40px;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 6px;
  border: solid 4px #f0862e;
  background-color: #f0862e;
  box-sizing: border-box;
`;

const PcrButton = styled(Flex)`
  height: 40px;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  border-radius: 6px;
  border: solid 4px #5a91db;
  background-color: #5a91db;
  box-sizing: border-box;
`;

const ReserveButton = styled(Flex)`
  height: 80px;
  width: 250px;
  margin: 10px;
  border-radius: 6px;
  border: solid 4px #4abaa7;
  background-color: #4ab8ba;
  box-sizing: border-box;
`;
const ReserveButtonCl = styled(Flex)`
  height: 80px;
  width: 250px;
  margin: 10px;
  border-radius: 6px;
  border: solid 4px #0f79d1;
  background-color: #0f79d1;
  box-sizing: border-box;
`;

const WhiteText = styled.div`
  font-size: 18px;
  color: #ffffff;
`;

const AccentButton = styled.span`
  border: 3px solid #f7b13c;
  border-radius: 6px;
  background-color: #fffff0;
  padding: 0.5em;
  margin: 0.5em;
`;

const Span = styled.span`
  font-size: 0.8em;
`;

const IndexPage = ({ data: { allMarkdownRemark } }) => {
  const { size } = useTrackedState();
  const announcements = allMarkdownRemark.edges
    .filter((edge) => !!edge.node.frontmatter.date)
    .map(
      ({
        node: {
          id,
          excerpt,
          frontmatter: { date, title, path },
        },
      }) => ({
        id,
        excerpt,
        date,
        title,
        path,
      })
    );
  return (
    <MainLayout topTitle="HOME">
      <HomeTopArea size={size} />

      <ContentAtHome title="おしらせ" size={size}>
        <Wrapper>
          <AnnouncementsArea data={announcements} size={size} hasLimit={true} />
        </Wrapper>
      </ContentAtHome>

      <ContentAtHome title="web予約ができるようになりました" size={size}>
        <Wrapper>
          <P
            css={`
              text-align: center;
            `}
          >
            院長以外の外来診察と物理療法のWeb予約ができるようになりました。
            <br />
            下記のボタンより予約ページに移動します。
            <br />
          </P>
          <Flex
            wrap="true"
            justifyCenter
            css={`
              margin-bottom: 10px;
            `}
          >
            <FlexItem>
              <Link to="https://quiq-0334003288.peernist.co.jp/WT" external>
                <ReserveButtonCl center>
                  <WhiteText>外来予約</WhiteText>
                </ReserveButtonCl>
              </Link>
            </FlexItem>
            <FlexItem>
              <Link to="https://reserva.be/hirahatacovidtest" external>
                <ReserveButton center>
                  <WhiteText>物理療法の予約</WhiteText>
                </ReserveButton>
              </Link>
            </FlexItem>
          </Flex>
        </Wrapper>
      </ContentAtHome>

      <ContentAtHome title="診察案内" size={size}>
        <Wrapper>
          <MedicalGuidanceArea size={size} />
        </Wrapper>
      </ContentAtHome>

      <ContentAtHome title="院内ではマスクの着用をお願いします" size={size}>
        <Wrapper>
          <Flex
            wrap="true"
            justifyAround
            css={`
              margin-bottom: 10px;
            `}
          ></Flex>
          <P>
            マスクの着用について「屋内・屋外を問わず個人の判断に委ねる」との方針が厚生労働省より発表されましたが、医療機関の受診時はマスクの着用が推奨されています。
            <br />
            当クリニックでは皆様が安心して過ごせるよう、引き続き院内ではマスク着用を原則とさせていただきます。
            <br />
            皆様のご理解とご協力をお願いいたします。
            <br />
            <br />
            マスクをお忘れの方には1枚100円で販売しております。お気軽に受付へお声掛けください。
          </P>
        </Wrapper>
      </ContentAtHome>

      <ContentAtHome title="現在お問い合わせのお電話が混み合っております" size={size}>
        <Wrapper>
          <P
            css={`
              text-align: center;
            `}
          >
            <br />
            現在間違い電話が多発しており、他の方の迷惑になっております。
            <br />
            お電話いただく際は、必ず番号をご確認いただきますようお願い申し上げます。
            <br />
            <br />
            <Link to="/announcements/2022-04-25">
              <AccentButton>詳細はこちらをご覧ください</AccentButton>
            </Link>
          </P>
        </Wrapper>
      </ContentAtHome>

      <ContentAtHome title="アクセス" size={size}>
        <Wrapper>
          <AccessInfoArea size={size} />
        </Wrapper>
      </ContentAtHome>
      <ContentAtHome title="求人情報" size={size}>
        <Wrapper>
          <P
            css={`
              text-align: center;
            `}
          >
            現在、下記の職種の募集をしております。
            <br />
            <br />
            医療事務（フルタイム）、看護師・准看護師（フルタイム・パートタイム）
            <br />
            <br />
            <br />
            詳細は
            <Link to={'/recruit'}>
              <AccentButton>求人情報</AccentButton>
            </Link>
            をご覧ください。
          </P>
        </Wrapper>
      </ContentAtHome>
      <ContentAtHome title="掲載・出演メディア一覧" size={size}>
        <Wrapper>
          <P>
            <Link
              to="https://www3.nhk.or.jp/news/special/coronavirus/medical/detail/detail_77.html"
              external
            >
              NHK NEWS WEB『コロナ “後遺症 若い世代にも”　700人以上診療の医師訴える』
            </Link>
            <br />
            <Link to="https://www.nhk.or.jp/gendai/articles/4475/" external>
              NHK クローズアップ現代＋『後遺症が苦しい…新型コロナ “治療後”の悩み』
            </Link>
            <br />
            <Link
              to="https://news.yahoo.co.jp/articles/27b84387755505239c24a97c766754e33e499ee3?page=1"
              external
            >
              NEWS ポストセブン『コロナ患者の87.4％に後遺症　疲労、呼吸困難、関節痛など 』
            </Link>
            <br />
            <Link to="https://www.youtube.com/watch?v=stS8lvu4v48&feature=youtu.be" external>
              news
              zero『「新型コロナ」後遺症で“脱毛”５割訴え…専門外来に』（2020年12月4日放送分より）
            </Link>
            <br />
            <Link to="https://mainichi.jp/articles/20201129/k00/00m/040/200000c" external>
              毎日新聞『呼吸困難や倦怠感…実は深刻なコロナ後遺症　病院で相手にされず　医師「国は対策を」』
            </Link>
            <br />
            <Link
              to="https://news.yahoo.co.jp/articles/72e9a988c25ca22347741823a17fdc64972081d3"
              external
            >
              SmartFLASH『新型コロナ「後遺症外来」に女性が殺到…“脳の霧”で仕事にも支障』
            </Link>
            <br />
            <Link to="https://www.ktv.jp/news/feature/20201208/" external>
              関西テレビ『元の生活に戻れず…新型コロナ後遺症とは？『会社解雇される人も…』』
            </Link>
            <br />
            <Link to="https://www.fnn.jp/articles/-/121563" external>
              FNNプライムオンライン『“コロナ後遺症”の苦しみ　倦怠感 全身の痛み数カ月』
            </Link>
            <br />
          </P>
        </Wrapper>
      </ContentAtHome>
      <ContentAtHome title="ご寄付のお願い" size={size}>
        <Wrapper>
          <P>
            当院は 2020 年 3 月から新型コロナ後遺症の診療を開始し、2023 年 1 月時点で、5000
            人以上の新型コロナ後遺症の患者様を診察させていただいております。
            <br />
            第一種情報処理技術者の資格を持ち、長年データベース構築をしてきた院長をはじめ、プログラマー、インフラエンジニア、ウェブデザイナーなどを擁し、IT
            化を進めることによって、他に類を見ないコロナ後遺症データベースを構築し、その解析結果をいち早く一般に公開。
            <br />
            多くの医師向けセミナーでも講演させていただくなどしております。
            <br />
            一方で、新型コロナ後遺症の診療は、高血圧などの一般的な疾患に比べて非常に時間がかかることが多いにもかかわらず、設定されている診療報酬が低く、経営的には非常に厳しい状態です。
            <br />
            研究・治療のための投資・人件費のねん出も思うようにいかないのが現状で、メールはあふれ、電話は鳴り続けているのに、受付を増やすこともできません。
            <br />
            そこで、サービスの向上、施設・設備・研究体制の維持にかかる費用のため、寄付をお願いすることといたしました。
            <br />
            事情ご賢察のうえ、多数のご支援を賜りますようお願い申し上げます。
            <br />
            <br />
            <br />
            ご寄付はクレジットカードまたは下記口座への振り込みをご利用ください。
            <br />
            <br />
            <Link to="https://congrant.com/credit/form?project_id=6073" extarnal>
              <AccentButton>クレジットカード</AccentButton>
            </Link>
            <br />
            <br />
            朝日信用金庫
            <br />
            小石川支店
            <br />
            普通預金　 0301126
            <br />
            医療法人社団創友会　イ）ソウユウカイ
            <br />
            <br />
            <br />
            ※ ご利用の金融機関によっては、振込手数料などの各種手数料が別途かかる場合がございます。
            <br />※ <b>当院へのご寄付は寄付金控除の対象ではございません。</b>予めご了承ください。
            <br />
            ※ 受領証が必要な場合、氏名と住所をメールにてご連絡ください。
            <br />
            お振込の場合は1〜2週間、クレジットカードの場合は1〜2ヶ月で発行させていただきます。
            <br />
            <br />
            お問い合わせは、
            <Link to="/contact">
              <AccentButton>お問い合わせフォーム</AccentButton>
            </Link>
            からお願いいたします。
            <br />
            お電話でのお問い合わせは、混雑のため、お控えいただきますよう、お願い申し上げます。
          </P>
        </Wrapper>
      </ContentAtHome>
    </MainLayout>
  );
};
export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 5
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/announcements/" } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 200)
          frontmatter {
            date(formatString: "YYYY/MM/DD")
            title
            path
          }
        }
      }
    }
  }
`;
